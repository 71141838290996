<template>
  <Layout>
    <div class="container mt-4 py-5">
      <div class="row">
        <div class="col-12 col-lg-3 mb-3">
          <h2 class="color-institucional">PREP</h2>

          <b-button v-b-toggle.collapse-1 variant="primary" class="w-100 d-block">
            Conoce el PREP
          </b-button>
          <b-collapse visible id="collapse-1" class="mt-2">
            <b-list-group>
              <b-list-group-item
                to="/centro-de-ayuda/prep/conoce/que-es"
              >
                ¿Qué es el PREP?
              </b-list-group-item>
              <b-list-group-item
                to="/centro-de-ayuda/prep/conoce/como-opera"
              >
                ¿Cómo opera el PREP?
              </b-list-group-item>
              <b-list-group-item
                to="/centro-de-ayuda/prep/conoce/difusores-oficiales"
              >
                Difusores oficiales del PREP 2021
              </b-list-group-item>
              <b-list-group-item
                active
                to="/centro-de-ayuda/prep/conoce/linea-tiempo"
              >
                Línea del Tiempo PREP
              </b-list-group-item>
            </b-list-group>
          </b-collapse>
          <br />

          <b-button v-b-toggle.collapse-2 variant="primary" class="w-100 d-block">
            Navegación PREP
          </b-button>
          <b-collapse visible id="collapse-2" class="mt-2">
            <b-list-group>
              <b-list-group-item
                to="/centro-de-ayuda/prep/navegacion/como-navego"
              >
                ¿Cómo navego en el PREP?
              </b-list-group-item>
              <b-list-group-item
                to="/centro-de-ayuda/prep/navegacion/elemento-apoyo"
              >
                Elementos de apoyo
              </b-list-group-item>
            </b-list-group>
          </b-collapse>
          <br />

          <b-button v-b-toggle.collapse-3 variant="primary" class="w-100 d-block">
            Actas en PREP
          </b-button>
          <b-collapse visible id="collapse-3" class="mt-2">
            <b-list-group>
              <b-list-group-item
                to="/centro-de-ayuda/prep/actas/que-es-acta"
              >
                ¿Qué es el Acta PREP?
              </b-list-group-item>
              <b-list-group-item
                to="/centro-de-ayuda/prep/actas/consultar-imagen"
              >
                ¿Cómo consultar la imagen del Acta PREP?
              </b-list-group-item>
              <b-list-group-item
                to="/centro-de-ayuda/prep/actas/estatus-digitalizacion-traslado"
              >
                Estatus, Digitalización y Traslado del Acta PREP
              </b-list-group-item>
              <b-list-group-item
                to="/centro-de-ayuda/prep/actas/observaciones"
              >
                Observaciones en Acta PREP
              </b-list-group-item>
              <b-list-group-item
                to="/centro-de-ayuda/prep/actas/validez"
              >
                Validez del Acta PREP
              </b-list-group-item>
            </b-list-group>
          </b-collapse>
          <br />

          <b-button
            to="/centro-de-ayuda/prep/base-de-datos/interpretacion"
            variant="primary"
            class="w-100 d-block"
          >
            Base de Datos
          </b-button>
        </div>
        <div class="col-12 col-lg-9">
          <b-breadcrumb>
            <b-breadcrumb-item to="/centro-de-ayuda" class="color-terciario">
              Inicio
            </b-breadcrumb-item>
            <b-breadcrumb-item>
              PREP
            </b-breadcrumb-item>
            <b-breadcrumb-item>
              Conoce el PREP
            </b-breadcrumb-item>
            <b-breadcrumb-item>
              <b>
                Linea del Tiempo PREP
              </b>
            </b-breadcrumb-item>
          </b-breadcrumb>

          <h2 class="text-center color-institucional">
            Linea del Tiempo PREP
          </h2>

          <div class="grid-tiempo">
            <div class="tiempo text-center">
              <h1>
                1991
              </h1>
            </div>
            <div class="descripcion">
              <div class="d-block w-100 py-5 px-3 text-center detalle-block">
                <div class="grid-interno">
                  <div class="fecha">
                    <h3>
                      18 AGO
                    </h3>
                  </div>
                  <div class="tiempoOperacion">
                    <label for="">
                      Tiempo de operación
                    </label>

                    <p>
                      72 horas
                    </p>
                  </div>
                  <div class="actas">
                    <label for="">Total de Actas:</label>

                    <p>
                      71.82%
                    </p>
                  </div>
                </div>
              </div>
              <div class="d-block w-100 px-3 py-5 descripcion-block">
                <div class="row align-items-center">
                  <div class="col-12 col-lg-6 mb-3">
                    <h4>
                      Elección Federal Cargos a elegir: Diputaciones
                    </h4>

                    <ul>
                      <li>
                        Creación del Sistema de Información de los
                        Resultados Electorales Preliminares (SIRE).
                      </li>
                      <li>
                        Transmisión de datos de las Actas de Escrutinio
                        y Cómputo de las casillas, provenientes de los
                        Consejos Distritales, a través de fax.
                      </li>
                    </ul>
                  </div>
                  <div class="col-12 col-lg-6 mb-3">
                    <img src="https://ayuda.ine.mx/2021/informate/assets/img/prep/tiempo-1.svg" alt="" class="img-fluid mx-auto d-block">
                  </div>
                </div>
              </div>
            </div>
          </div>

          <div class="grid-tiempo">
            <div class="tiempo text-center">
              <h1>
                1994
              </h1>
            </div>
            <div class="descripcion">
              <div class="d-block w-100 py-5 px-3 text-center detalle-block">
                <div class="grid-interno">
                  <div class="fecha">
                    <h3>
                      21 AGO
                    </h3>
                  </div>
                  <div class="tiempoOperacion">
                    <label for="">
                      Tiempo de operación
                    </label>

                    <p>
                      96 horas
                    </p>
                  </div>
                  <div class="actas">
                    <label for="">Total de Actas:</label>

                    <p>
                      92.27%
                    </p>
                  </div>
                </div>
              </div>
              <div class="d-block w-100 px-3 py-5 descripcion-block">
                <div class="row align-items-center">
                  <div class="col-12 col-lg-6 mb-3">
                    <h4>
                      Elección Federal Cargos a elegir: Presidencia, Senadurías y Diputaciones
                    </h4>

                    <ul>
                      <li>
                        Primera elección presidencial organizada por el IFE.
                      </li>
                      <li>
                        Creación del Programa de Resultados Electorales Preliminares (PREP).
                      </li>
                      <li>
                        Instalación de 300 Centros de Acopio y Transmisión (CEDAT) y 2
                        Centros Nacionales de Recepción de Resultados Electorales
                        Preliminares (CENARREP).
                      </li>
                      <li>
                        La transmisión de datos se hizo con 7 mil 347 líneas telefónicas
                        y utilizando 6 mil equipos Terminales de Captura Remota (TCR).
                      </li>
                    </ul>
                  </div>
                  <div class="col-12 col-lg-6 mb-3">
                    <img src="https://ayuda.ine.mx/2021/informate/assets/img/prep/tiempo-2.svg" alt="" class="img-fluid mx-auto d-block">
                  </div>
                </div>
              </div>
            </div>
          </div>

          <div class="grid-tiempo">
            <div class="tiempo text-center">
              <h1>
                1997
              </h1>
            </div>
            <div class="descripcion">
              <div class="d-block w-100 py-5 px-3 text-center detalle-block">
                <div class="grid-interno">
                  <div class="fecha">
                    <h3>
                      06 JUL
                    </h3>
                  </div>
                  <div class="tiempoOperacion">
                    <label for="">
                      Tiempo de operación
                    </label>

                    <p>
                      23 horas
                    </p>
                  </div>
                  <div class="actas">
                    <label for="">Total de Actas:</label>

                    <p>
                      87.2%
                    </p>
                  </div>
                </div>
              </div>
              <div class="d-block w-100 px-3 py-5 descripcion-block">
                <div class="row align-items-center">
                  <div class="col-12 col-lg-6 mb-3">
                    <h4>
                      Elección Federal Cargos a elegir: Diputaciones
                    </h4>

                    <ul>
                      <li>
                        Designación de un Comité Técnico Asesor con
                        la participación de un grupo de expertos.
                      </li>
                      <li>
                        Habilitación de 300 CEDAT<sup>1</sup> y 2 CENARREP<sup>2</sup>.
                      </li>
                      <li>
                        Elección del Jefe de Gobierno del Distrito Federal.
                      </li>
                      <li>
                        Por primera vez, se publicó el PREP en internet.
                      </li>
                      <li>
                        Captura de datos con TCR<sup>3</sup> y transmisión vía módem.
                      </li>
                    </ul>
                  </div>
                  <div class="col-12 col-lg-6 mb-3">
                    <img src="https://ayuda.ine.mx/2021/informate/assets/img/prep/tiempo-3.svg" alt="" class="img-fluid mx-auto d-block">
                  </div>
                </div>
              </div>
            </div>
          </div>

          <div class="grid-tiempo">
            <div class="tiempo text-center">
              <h1>
                2000
              </h1>
            </div>
            <div class="descripcion">
              <div class="d-block w-100 py-5 px-3 text-center detalle-block">
                <div class="grid-interno">
                  <div class="fecha">
                    <h3>
                      02 JUL
                    </h3>
                  </div>
                  <div class="tiempoOperacion">
                    <label for="">
                      Tiempo de operación
                    </label>

                    <p>
                      23 horas
                    </p>
                  </div>
                  <div class="actas">
                    <label for="">Total de Actas:</label>

                    <p>
                      93.08%
                    </p>
                  </div>
                </div>
              </div>
              <div class="d-block w-100 px-3 py-5 descripcion-block">
                <div class="row align-items-center">
                  <div class="col-12 col-lg-6 mb-3">
                    <h4>
                      Elección Federal Cargos a elegir: Presidencia, Senadurías y Diputaciones
                    </h4>

                    <ul>
                      <li>
                        Los resultados preliminares de elección
                        presidencial son publicados en internet.
                      </li>
                      <li>
                        Transmisión de resultados mediante 28 medios de comunicación autorizados.
                      </li>
                      <li>
                        Captura de datos con TCR<sup>3</sup> y transmisión vía módem.
                      </li>
                    </ul>
                  </div>
                  <div class="col-12 col-lg-6 mb-3">
                    <img src="https://ayuda.ine.mx/2021/informate/assets/img/prep/tiempo-4.svg" alt="" class="img-fluid mx-auto d-block">
                  </div>
                </div>
              </div>
            </div>
          </div>

          <div class="grid-tiempo">
            <div class="tiempo text-center">
              <h1>
                2003
              </h1>
            </div>
            <div class="descripcion">
              <div class="d-block w-100 py-5 px-3 text-center detalle-block">
                <div class="grid-interno">
                  <div class="fecha">
                    <h3>
                      06 JUL
                    </h3>
                  </div>
                  <div class="tiempoOperacion">
                    <label for="">
                      Tiempo de operación
                    </label>

                    <p>
                      24 horas
                    </p>
                  </div>
                  <div class="actas">
                    <label for="">Total de Actas:</label>

                    <p>
                      94.41%
                    </p>
                  </div>
                </div>
              </div>
              <div class="d-block w-100 px-3 py-5 descripcion-block">
                <div class="row align-items-center">
                  <div class="col-12 col-lg-6 mb-3">
                    <h4>
                      Elección Federal Cargos a elegir: Diputaciones
                    </h4>

                    <ul>
                      <li>
                        Se estableció la duración de operación del PREP de 24 horas.
                      </li>
                      <li>
                        Se desarrolló una nueva versión del sistema.
                      </li>
                      <li>
                        Se hacen mejoras tecnológicas en confiabilidad, integridad y transparencia.
                      </li>
                    </ul>
                  </div>
                  <div class="col-12 col-lg-6 mb-3">
                    <img src="https://ayuda.ine.mx/2021/informate/assets/img/prep/tiempo-5.svg" alt="" class="img-fluid mx-auto d-block">
                  </div>
                </div>
              </div>
            </div>
          </div>

          <div class="grid-tiempo">
            <div class="tiempo text-center">
              <h1>
                2006
              </h1>
            </div>
            <div class="descripcion">
              <div class="d-block w-100 py-5 px-3 text-center detalle-block">
                <div class="grid-interno">
                  <div class="fecha">
                    <h3>
                      02 JUL
                    </h3>
                  </div>
                  <div class="tiempoOperacion">
                    <label for="">
                      Tiempo de operación
                    </label>

                    <p>
                      24 horas
                    </p>
                  </div>
                  <div class="actas">
                    <label for="">Total de Actas:</label>

                    <p>
                      98.45%
                    </p>
                  </div>
                </div>
              </div>
              <div class="d-block w-100 px-3 py-5 descripcion-block">
                <div class="row align-items-center">
                  <div class="col-12 col-lg-6 mb-3">
                    <h4>
                      Elección Federal Cargos a elegir: Presidencia, Senadurías y Diputaciones
                    </h4>

                    <ul>
                      <li>
                        Captura de datos con TCR<sup>3</sup> y transmisión vía módem.
                      </li>
                      <li>
                        Transmisión de datos a través de la red del Instituto.
                      </li>
                      <li>
                        Precisión del PREP en el resultado de la elección presidencial,
                        con una diferencia de 0.04% respecto de los Cómputos Distritales.
                      </li>
                      <li>
                        Integración de los resultados del Voto de las y los Mexicanos Residentes
                        en el Extranjero, y publicada a través de Internet en formato HTML
                        (difusores) e Intranet (RedIFE).
                      </li>
                    </ul>
                  </div>
                  <div class="col-12 col-lg-6 mb-3">
                    <img src="https://ayuda.ine.mx/2021/informate/assets/img/prep/tiempo-6.svg" alt="" class="img-fluid mx-auto d-block">
                  </div>
                </div>
              </div>
            </div>
          </div>

          <div class="grid-tiempo">
            <div class="tiempo text-center">
              <h1>
                2009
              </h1>
            </div>
            <div class="descripcion">
              <div class="d-block w-100 py-5 px-3 text-center detalle-block">
                <div class="grid-interno">
                  <div class="fecha">
                    <h3>
                      05 JUL
                    </h3>
                  </div>
                  <div class="tiempoOperacion">
                    <label for="">
                      Tiempo de operación
                    </label>

                    <p>
                      24 horas
                    </p>
                  </div>
                  <div class="actas">
                    <label for="">Total de Actas:</label>

                    <p>
                      99.87%
                    </p>
                  </div>
                </div>
              </div>
              <div class="d-block w-100 px-3 py-5 descripcion-block">
                <div class="row align-items-center">
                  <div class="col-12 col-lg-6 mb-3">
                    <h4>
                      Elección Federal Cargos a elegir: Diputaciones
                    </h4>

                    <ul>
                      <li>
                        Se mantuvo el esquema de operación utilizado en el PREP del 2006.
                      </li>
                      <li>
                        Se integró una navegación por pestañas para facilitar al usuario
                        la consulta de información.
                      </li>
                    </ul>
                  </div>
                  <div class="col-12 col-lg-6 mb-3">
                    <img src="https://ayuda.ine.mx/2021/informate/assets/img/prep/tiempo-7.svg" alt="" class="img-fluid mx-auto d-block">
                  </div>
                </div>
              </div>
            </div>
          </div>

          <div class="grid-tiempo">
            <div class="tiempo text-center">
              <h1>
                2012
              </h1>
            </div>
            <div class="descripcion">
              <div class="d-block w-100 py-5 px-3 text-center detalle-block">
                <div class="grid-interno">
                  <div class="fecha">
                    <h3>
                      01 JUL
                    </h3>
                  </div>
                  <div class="tiempoOperacion">
                    <label for="">
                      Tiempo de operación
                    </label>

                    <p>
                      24 horas
                    </p>
                  </div>
                  <div class="actas">
                    <label for="">Total de Actas:</label>

                    <p>
                      98.45%
                    </p>
                  </div>
                </div>
              </div>
              <div class="d-block w-100 px-3 py-5 descripcion-block">
                <div class="row align-items-center">
                  <div class="col-12 col-lg-6 mb-3">
                    <h4>
                      Elección Federal Cargos a elegir: Presidencia, Senadurías y Diputaciones
                    </h4>

                    <ul>
                      <li>
                        Digitalización de las AEC<sup>4</sup>,
                        su publicación concluyó al día siguiente de la Jornada Electoral.
                      </li>
                      <li>
                        Se llevó a cabo una auditoría de seguridad informática
                        por la Universidad Nacional autónoma de México (UNAM).
                      </li>
                      <li>
                        Digitalización a través de escáner.
                      </li>
                      <li>
                        Diseño del portal del PREP visible en equipo de escritorio y móvil.
                      </li>
                    </ul>
                  </div>
                  <div class="col-12 col-lg-6 mb-3">
                    <img src="https://ayuda.ine.mx/2021/informate/assets/img/prep/tiempo-8.svg" alt="" class="img-fluid mx-auto d-block">
                  </div>
                </div>
              </div>
            </div>
          </div>

          <div class="grid-tiempo">
            <div class="tiempo text-center">
              <h1>
                2015
              </h1>
            </div>
            <div class="descripcion">
              <div class="d-block w-100 py-5 px-3 text-center detalle-block">
                <div class="grid-interno">
                  <div class="fecha">
                    <h3>
                      07 JUL
                    </h3>
                  </div>
                  <div class="tiempoOperacion">
                    <label for="">
                      Tiempo de operación
                    </label>

                    <p>
                      24 horas
                    </p>
                  </div>
                  <div class="actas">
                    <label for="">Total de Actas:</label>

                    <p>
                      98.63%
                    </p>
                  </div>
                </div>
              </div>
              <div class="d-block w-100 px-3 py-5 descripcion-block">
                <div class="row align-items-center">
                  <div class="col-12 col-lg-6 mb-3">
                    <h4>
                      Elección Federal Cargos a elegir: Diputaciones
                    </h4>

                    <ul>
                      <li>
                        Utilización de códigos QR para identificar las Actas.
                      </li>
                      <li>
                        Sustitución de las TCR<sup>3</sup> por equipos de cómputo.
                      </li>
                      <li>
                        Digitalización de las Actas previo a la captura de datos.
                      </li>
                      <li>
                        Captura de datos con base en las imágenes de las Actas.
                      </li>
                      <li>
                        Publicación de las imágenes de las Actas durante la operación del PREP.
                      </li>
                    </ul>
                  </div>
                  <div class="col-12 col-lg-6 mb-3">
                    <img src="https://ayuda.ine.mx/2021/informate/assets/img/prep/tiempo-9.svg" alt="" class="img-fluid mx-auto d-block">
                  </div>
                </div>
              </div>
            </div>
          </div>

          <div class="grid-tiempo">
            <div class="tiempo text-center">
              <h1>
                2016
              </h1>
            </div>
            <div class="descripcion">
              <div class="d-block w-100 py-5 px-3 text-center detalle-block">
                <div class="grid-interno">
                  <div class="fecha">
                    <h3>
                      05 JUL
                    </h3>
                  </div>
                  <div class="tiempoOperacion">
                    <label for="">
                      Tiempo de operación
                    </label>

                    <p>
                      CDMX: 23:25 horas <br />
                      Sinaloa: 24 horas
                    </p>
                  </div>
                  <div class="actas">
                    <label for="">Total de Actas:</label>

                    <p>
                      CDMX: 100% <br />
                      Sinaloa: 96.78%
                    </p>
                  </div>
                </div>
              </div>
              <div class="d-block w-100 px-3 py-5 descripcion-block">
                <div class="row align-items-center">
                  <div class="col-12 col-lg-6 mb-3">
                    <h4>
                      Elección de la Asamblea Constituyente
                      para la CDMX y Proceso Electoral Local Sinaloa
                    </h4>

                    <ul>
                      <li>
                        Aplicación de un piloto de digitalización de las
                        Actas, a través de la aplicación móvil PREP Casilla.
                      </li>
                      <li>
                        En Sinaloa, se realizó por primera vez la captura y
                        verificación en Centros independientes a los CATD<sup>5</sup>,
                        manteniendo el acopio y digitalización.
                      </li>
                    </ul>
                  </div>
                  <div class="col-12 col-lg-6 mb-3">
                    <img src="https://ayuda.ine.mx/2021/informate/assets/img/prep/tiempo-10.svg" alt="" class="img-fluid mx-auto d-block">
                  </div>
                </div>
              </div>
            </div>
          </div>

          <div class="grid-tiempo">
            <div class="tiempo text-center">
              <h1>
                2017
              </h1>
            </div>
            <div class="descripcion">
              <div class="d-block w-100 py-5 px-3 text-center detalle-block">
                <div class="grid-interno">
                  <div class="fecha">
                    <h3>
                      04 JUN
                    </h3>
                  </div>
                  <div class="tiempoOperacion">
                    <label for="">
                      Tiempo de operación
                    </label>

                    <p>
                      24 horas
                    </p>
                  </div>
                  <div class="actas">
                    <label for="">Total de Actas:</label>

                    <p>
                      Veracruz: 99.99% <br />
                      Nayarit: 98.32%
                    </p>
                  </div>
                </div>
              </div>
              <div class="d-block w-100 px-3 py-5 descripcion-block">
                <div class="row align-items-center">
                  <div class="col-12 col-lg-6 mb-3">
                    <h4>
                      Elección Local Cargos a elegir: Gubernaturas de Veracruz y Nayarit
                    </h4>

                    <ul>
                      <li>
                        Mayor alcance en la digitalización de
                        las Actas desde la aplicación PREP Casilla.
                      </li>
                      <li>
                        Digitalización de dispositivos móviles
                        (PREP Casilla) por primera ocasión en Veracruz y Nayarit.
                      </li>
                      <li>
                        Instalación de Centros de Captura y Verificación (CCV).
                      </li>
                      <li>
                        Operación de Centros de Acopio y Transmisión de Datos (CATD) en Veracruz.
                      </li>
                    </ul>
                  </div>
                  <div class="col-12 col-lg-6 mb-3">
                    <img src="https://ayuda.ine.mx/2021/informate/assets/img/prep/tiempo-11.svg" alt="" class="img-fluid mx-auto d-block">
                  </div>
                </div>
              </div>
            </div>
          </div>

          <div class="grid-tiempo">
            <div class="tiempo text-center">
              <h1>
                2018
              </h1>
            </div>
            <div class="descripcion">
              <div class="d-block w-100 py-5 px-3 text-center detalle-block">
                <div class="grid-interno">
                  <div class="fecha">
                    <h3>
                      01 JUL
                    </h3>
                  </div>
                  <div class="tiempoOperacion">
                    <label for="">
                      Tiempo de operación
                    </label>

                    <p>
                      25 horas
                    </p>
                  </div>
                  <div class="actas">
                    <label for="">Total de Actas:</label>

                    <p>
                      93.56%
                    </p>
                  </div>
                </div>
              </div>
              <div class="d-block w-100 px-3 py-5 descripcion-block">
                <div class="row align-items-center">
                  <div class="col-12 col-lg-6 mb-3">
                    <h4>
                      Elecciones Federales Cargos a elegir: Presidencia, Senadurías y Diputaciones
                    </h4>

                    <ul>
                      <li>
                        Primera digitalización y transmisión de las
                        Actas desde la casilla en una Elección Federal.
                      </li>
                      <li>
                        Participación de más de 38,766 CAE<sup>6</sup> y
                        6,277 SE<sup>7</sup> para digitalizar y transmitir
                        AEC<sup>4</sup> desde casilla.
                      </li>
                      <li>
                        Operación de 300 CATD<sup>5</sup> y fue la primera vez
                        que en un PREP Federal se habilitan 2 CCV<sup>8</sup>.
                      </li>
                      <li>
                        Procesamiento de más de 470 mil AEC
                        correspondientes a los tres cargos de Elección Federal.
                      </li>
                      <li>
                        Disponibilidad del portal PREP a partir de
                        las 20:00 horas del 1º de julio (Tiempo del Centro).
                      </li>
                    </ul>
                  </div>
                  <div class="col-12 col-lg-6 mb-3">
                    <img src="https://ayuda.ine.mx/2021/informate/assets/img/prep/tiempo-12.svg" alt="" class="img-fluid mx-auto d-block">
                  </div>
                </div>
              </div>
            </div>
          </div>

          <div class="grid-tiempo">
            <div class="tiempo text-center">
              <h1>
                2019
              </h1>
            </div>
            <div class="descripcion">
              <div class="d-block w-100 py-5 px-3 text-center detalle-block">
                <div class="grid-interno">
                  <div class="fecha">
                    <h3>
                      02 JUN
                    </h3>
                  </div>
                  <div class="tiempoOperacion">
                    <label for="">
                      Tiempo de operación
                    </label>

                    <p>
                      24 horas
                    </p>
                  </div>
                  <div class="actas">
                    <label for="">Total de Actas:</label>

                    <p>
                      100%
                    </p>
                  </div>
                </div>
              </div>
              <div class="d-block w-100 px-3 py-5 descripcion-block">
                <div class="row align-items-center">
                  <div class="col-12 col-lg-6 mb-3">
                    <h4>
                      Elecciones Extraordinarias Cargos a elegir: Gubernatura de Puebla
                    </h4>

                    <ul>
                      <li>
                        Se procesó el 100% de las AEC<sup>4</sup>.
                      </li>
                      <li>
                        El Programa duró menos de 24 horas.
                      </li>
                      <li>
                        PREP Casilla tuvo 84.16% de efectividad.
                      </li>
                    </ul>
                  </div>
                  <div class="col-12 col-lg-6 mb-3">
                    <img src="https://ayuda.ine.mx/2021/informate/assets/img/prep/tiempo-13.svg" alt="" class="img-fluid mx-auto d-block">
                  </div>
                </div>
              </div>
            </div>
          </div>

          <div class="grid-tiempo mb-5">
            <div class="tiempo text-center">
              <h1>
                2021
              </h1>
            </div>
            <div class="descripcion">
              <div class="d-block w-100 py-5 px-3 text-center detalle-block">
                <div class="grid-interno">
                  <div class="fecha">
                    <h3>
                      06 JUN
                    </h3>
                  </div>
                  <div class="tiempoOperacion">
                    <!-- <label for="">
                      Tiempo de operación
                    </label>

                    <p>
                      25 horas
                    </p> -->
                  </div>
                  <div class="actas">
                    <!-- <label for="">Total de Actas:</label>

                    <p>
                      93.56%
                    </p> -->
                  </div>
                </div>
              </div>
              <div class="d-block w-100 px-3 py-5 descripcion-block">
                <div class="row align-items-center">
                  <div class="col-12 col-lg-6 mb-3">
                    <h4>
                      Elección Federal Cargos a elegir: Diputaciones
                    </h4>

                    <ul>
                      <li>
                        Nuevo diseño del sitio PREP para mejorar la
                        comprensión de los datos, experiencia de usuario y accesibilidad.
                      </li>
                      <li>
                        Se incluye el Voto de las Personas en Prisión Preventiva
                      </li>
                      <li>
                        Coahuila y Jalisco implementarán el voto electrónico,
                        a través de la Urna Electrónica, con 50 casillas cada uno.
                      </li>
                      <li>
                        El ente auditor del PREP es la Universidad Autónoma Metropolitana (UAM).
                      </li>
                      <li>
                        Robustecimiento del COTAPREP, en materias como: estadística y/o ciencia
                        de datos; tecnologías de la información y comunicaciones; investigación
                        de operaciones o ciencia política, preferentemente con conocimientos
                        -en materia electoral.
                      </li>
                    </ul>
                  </div>
                  <div class="col-12 col-lg-6 mb-3">
                    <img src="https://ayuda.ine.mx/2021/informate/assets/img/prep/tiempo-14.svg" alt="" class="img-fluid mx-auto d-block">
                  </div>
                </div>
              </div>
            </div>
          </div>

          <div class="mb-5 text-center">
            <p _ngcontent-qrr-c65="" class="referencias"><sup _ngcontent-qrr-c65="">1</sup>CEDAT: Centros de Acopio y Transmisión | <sup _ngcontent-qrr-c65="">2</sup>CENARREP: Centros Nacionales de Recepción de Resultados Electorales Preliminares | <sup _ngcontent-qrr-c65="">3</sup>TCR: Terminales de Captura Remota | <sup _ngcontent-qrr-c65="">4</sup>AEC: Actas de Escrutinio y Cómputo | <sup _ngcontent-qrr-c65="">5</sup>CATD: Centro de Acopio y Transmisión de Datos | <sup _ngcontent-qrr-c65="">6</sup>CAE: Capacitador Asistente Electoral | <sup _ngcontent-qrr-c65="">7</sup>SE: Supervisor Electoral | <sup _ngcontent-qrr-c65="">8</sup>CCV: Centro de Captura y Verificación | <sup _ngcontent-qrr-c65="">9</sup>CRID: Centro de Recepción de Imágenes y Datos | <br _ngcontent-qrr-c65=""><sup _ngcontent-qrr-c65="">10</sup> CV: Centro de Verificación </p>
          </div>

          <a
            href="https://ayuda.ine.mx/2021/informate/assets/docs/Prep/Conoce_el_PREP_Infografias/Linea_Tiempo_PREP.pdf"
            target="_blank"
            class="btn btn-primary mx-auto d-block"
          >
            Descarga Infografía
          </a>
        </div>
      </div>
    </div>
  </Layout>
</template>

<script>
import Layout from '@/views/CentroDeAyuda/Layout.vue';

export default {
  components: {
    Layout,
  },
};
</script>

<style lang="scss" scoped>
ul {
  list-style-type: disc;
  margin-left: 30px;
}

.list-group-item {
  z-index: 0;
}

.grid-tiempo {
  display: grid;
  align-items: center;
  grid-template-columns: 25% 1fr;
  color: white;

  > img {
    max-width: 45px;
  }

  > p {
    margin-bottom: 0;
  }

  .grid-interno {
    display: grid;
    align-items: center;
    gap: 45px;
    grid-template-columns: 1fr 1fr 1fr;
  }

  &:nth-child(odd) {
    background-color: #766CAE;

    .detalle-block {
      background-color: #5C5688;
    }

    .descripcion-block {
      background-color: #4C4871;
    }
  }

  &:nth-child(even) {
    background-color: #E50077;

    .detalle-block {
      background-color: #962464;
    }

    .descripcion-block {
      background-color: #6B1046;
    }
  }
}

.list-group-item.active {
  background-color: #D2127E;
  border-color: transparent;
}
</style>
